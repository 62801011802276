.year {
  position: absolute;
  font-size: 4rem;
  letter-spacing: -0.04em;
  line-height: 1;
  color: #875c1e;
  text-shadow: 0 1px #e8c999;
  top: 48%;
  left: 45%;
}
