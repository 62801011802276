@media (--breakpointLg) {
  /* support fixed position full width nav in desktop mode */
  .globalNavPadding {
    padding-top: var(--globalNavHeightDesktop);
  }
}

/* 
  Keeps footer toward the bottom of the viewport, which is especially
  important while the page is loading, without this class the footer
  is rendered high up on the page when content is empty/loading
  or the content on a page is 'short'.
*/
.pushFooterOutOfView {
  min-height: calc(100vh - var(--globalNavHeightXs));
}
@media (--breakpointXs) {
  .pushFooterOutOfView {
    min-height: calc(100vh - var(--globalNavHeightMobile));
  }
}
@media (--breakpointMd) {
  .pushFooterOutOfView {
    min-height: calc(100vh - var(--globalNavHeightTablet));
  }
}
@media (--breakpointLg) {
  .pushFooterOutOfView {
    min-height: calc(100vh - var(--globalNavHeightDesktop));
  }
}

.passOnFullHeight {
  /* flex-grow: 1 allows a flex child to stretch to fill all unused
   * vertical space in the flex parent.
   * Children of this element should have access to this element's
   * full height (ie. with height: 100%), but there is a bug in Safari 
   * that will provide children access only to the intrinsic height
   * of this element.
   * This is fixed by adding flex-basis with any value besides 'auto'.
   */
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 0;
}
