:root {
  --markerSize: 57px;
  /* delay should match PROGRESS_BAR_ANIMATION_DURATION in the tracker index */
  --animationDelay: 500ms;
  /* duration should match CALLBACK_ANIMATION_DELAY in the tracker index */
  --animationDuration: 1500ms;
  --bloopScaleSmall: 0.25;
  --bloopScaleLarge: 1;
  --textOffset: 12px;
  --easing: cubic-bezier(0.25, 1, 0.25, 1);
}

.goalMarker {
  position: absolute;
  /* left property will be set dynamically once tracker UI is calculated */
  left: 0;
  top: 0;
  width: var(--markerSize);
  height: var(--markerSize);
  transform: translateY(-27px) translateX(-50%);
}

.goalMarkerText {
  /* need to declare color here instead of markup to allow for
     text color changes during bloop animation */
  color: var(--colorTextBlackSoft);
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateY(var(--textOffset)) translateX(-50%);
}

.goalMarkerBubble {
  width: var(--markerSize);
  height: var(--markerSize);
  border: solid 6px var(--colorBlack20);
  border-radius: 500px; /* overly large to make sure completely round in all browsers */
  transform: scale(var(--bloopScaleSmall));
}

.greenBubble {
  border: solid 6px var(--colorGreenAccent);
  background-color: var(--colorGreenAccent);
}

.goldBubble {
  border: solid 6px var(--colorGold);
  background-color: var(--colorGold);
}

.animate {
  overflow: hidden;
  animation-name: bloop;
  animation-delay: var(--animationDelay);
  animation-duration: var(--animationDuration);
  animation-iteration-count: 1;
  animation-timing-function: var(--easing);
}

@keyframes bloop {
  0% {
    transform: scale(var(--bloopScaleSmall));
  }
  5% {
    transform: scale(var(--bloopScaleLarge));
    /* compensate for border which grows along with the scale */
    border-width: 2px;
  }
  50% {
    border-color: var(--colorBlack20);
  }
  95% {
    transform: scale(var(--bloopScaleLarge));
    border-color: var(--colorGold);
    border-width: 2px;
  }
  100% {
    transform: scale(var(--bloopScaleSmall));
    border-color: var(--colorBlack20);
  }
}

.animate::after {
  content: '';
  position: absolute;
  right: 100%;
  bottom: 100%;
  background: var(--colorGold);
  width: 61px;
  height: 61px;
  border-radius: 100px;
  animation-name: shade;
  animation-delay: var(--animationDelay);
  animation-duration: var(--animationDuration);
  animation-iteration-count: 1;
  animation-timing-function: var(--easing);
}

@keyframes shade {
  0% {
    right: 70%;
    bottom: 90%;
  }
  40% {
    right: 70%;
    bottom: 90%;
  }
  60%,
  100% {
    right: -12%;
    bottom: -12%;
  }
}

.sparkle::after {
  content: url(./img/sparkle@2x.png);
  position: absolute;
  left: 50%;
  bottom: 50%;
  opacity: 0;
  animation-name: sparkle;
  animation-delay: var(--animationDelay);
  animation-duration: var(--animationDuration);
  animation-iteration-count: 1;
  animation-timing-function: var(--easing);
}

@keyframes sparkle {
  0% {
    opacity: 0;
    transform: scale(0.1);
  }
  55% {
    opacity: 0;
    transform: scale(0.1) translateY(0) translateX(0);
  }
  56% {
    opacity: 1;
  }
  60% {
    transform: scale(0.9) translateY(-20%) translateX(20%);
  }
  90% {
    transform: scale(0.9) translateY(-20%) translateX(20%);
    opacity: 1;
  }
  100% {
    transform: scale(0.1);
    opacity: 0;
  }
}

.animateText {
  animation-name: moveText;
  animation-delay: var(--animationDelay);
  animation-duration: var(--animationDuration);
  animation-iteration-count: 1;
  animation-timing-function: cubic-bezier(0.25, 1, 0.25, 1);
}

@keyframes moveText {
  0% {
    transform: translateY(var(--textOffset)) translateX(-50%);
  }
  5% {
    transform: translateY(-50%) translateX(-50%);
  }
  50% {
    color: var(--colorTextBlackSoft);
    transform: translateY(-50%) translateX(-50%) scale(1);
  }
  60% {
    color: var(--colorWhite);
    transform: translateY(-50%) translateX(-50%) scale(1.23529411);
  }
  90% {
    transform: translateY(-50%) translateX(-50%) scale(1.23529411);
    color: var(--colorWhite);
  }
  93% {
    transform: translateY(var(--textOffset)) translateX(-50%);
  }
  96% {
    transform: translateY(calc(var(--textOffset) + 5px)) translateX(-50%);
  }
  100% {
    transform: translateY(var(--textOffset)) translateX(-50%) scale(1);
    color: var(--colorTextBlackSoft);
  }
}
