.breadcrumbBar {
  box-shadow:
    0 1px 3px rgba(0, 0, 0, 0.1),
    0 2px 2px rgba(0, 0, 0, 0.06),
    0 0 2px rgba(0, 0, 0, 0.07);
  font-size: var(--textSize2);
  background-color: var(--colorNeutralCool);
}

.breadcrumbs {
  flex-wrap: wrap;
  padding-top: var(--space-2);
  padding-bottom: var(--space-2);
}

@media (--breakpointMd) {
  .breadcrumbs {
    padding-top: var(--space-3);
    padding-bottom: var(--space-3);
  }
}

@media (--breakpointLg) {
  .breadcrumbBar {
    position: sticky;
    z-index: 3;
    top: 0;
  }
}
