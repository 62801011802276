.globalNav {
  position: relative;
  /* layer above shadow of header crate */
  z-index: 2;
}

.globalNavOpen {
  /* make sure to layer above crust in menu when the mobile nav is open */
  z-index: 20;
}

@media (--breakpointLg) {
  .globalNavFixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }
}
