.linkOverlay {
  /* make sure linkOverlay is on top of star balance */
  z-index: 1;
}

.starBalance {
  font-size: 4.2rem;
  font-weight: bold;
  line-height: 0.8;
  /* adjusts number so small gap made by font isn't visible to user */
  margin-left: -3px;
}
