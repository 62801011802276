.contentContainerPadding {
  /*
   * Purposely written without using utility classes so that
   * consumers may easily override with their own utility classes
   */
  padding-top: var(--space-4);
  padding-bottom: var(--space-9);
}

@media (--breakpointLg) {
  .contentContainerPadding {
    padding-top: 100px;
    padding-bottom: 100px;
  }
}
