.nucleus {
  position: absolute;
  left: 50%;
  top: 50%;
}

.star {
  position: absolute;
  transition-timing-function: ease-in-out, ease-in-out;
  opacity: 0;
}

.starCore {
  height: 50px;
  width: 50px;
  transition: opacity;
  transition-duration: 2s;
  transition-timing-function: ease-in-out;
  opacity: 1;
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51 51' width='51' height='51'><path stroke='%23cab06c' stroke-width='3' stroke-linecap='round' fill='none' stroke-linejoin='round' class='s' d='M50 19L35 31l5 18L25 38 9 49 15 31 0 19h19l5-18L31 19H50z' /></svg>")
    no-repeat;
}

.starCoreFilled {
  background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 51 51' width='51' height='51' fill='%23cab06c'><path class='s' d='M50 19L35 31l5 18L25 38 9 49 15 31 0 19h19l5-18L31 19H50z'/></svg>")
    no-repeat;
}

.starAnimate0 {
  opacity: 1;
  transform: translate(-25px, -25px) !important;
}
.starAnimate90 {
  opacity: 1;
  transform: translate(-25px, -25px) rotate(90deg) !important;
}
.starAnimate180 {
  opacity: 1;
  transform: translate(-25px, -25px) rotate(180deg) !important;
}
.starAnimate270 {
  opacity: 1;
  transform: translate(-25px, -25px) rotate(270deg) !important;
}

.starAnimateCore {
  opacity: 0 !important;
}
