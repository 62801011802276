:root {
  --toastEntryDuration: 200ms;
  --toastExitDuration: 100ms;
}

.toast {
  background: var(--colorGreenStarbucks);
  color: var(--colorTextWhite);
  /* Base toast height. Corresponds to animation in `ToastSlider` component. */
  min-height: 67px;

  cursor: pointer;
}

@media (--breakpointLg) {
  .toast {
    right: 0;
    left: var(--headerCrateProportion);
  }
}

:global(.notification-enter).toast {
  transform: translateY(100%);
}

:global(.notification-enter-active).toast {
  transition: var(--toastEntryDuration) ease-out transform;
  transform: translateY(0%);
}

:global(.notification-exit).toast {
  transform: translateY(0%);
}

:global(.notification-exit-active).toast {
  transition: var(--toastExitDuration) ease-out transform;
  transform: translateY(100%);
}
