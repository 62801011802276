.base {
  position: fixed;
  bottom: 0;
  right: 0;
  /* make sure fraps sit above any other positioned 
   * elements that come afterward in the dom
   */
  z-index: 1;
}

@media (--breakpointCrateLayout) {
  .left {
    right: auto;
    left: var(--headerCrateProportion);
    transform: translateX(-100%);
  }
}

@media (--breakpointMd) {
  .md {
    position: relative !important;
    padding-right: 0;
  }
}

@media (--breakpointLg) {
  .lg {
    position: relative !important;
    padding-right: 0;
  }
}

@media (--breakpointXl) {
  .xl {
    position: relative !important;
    padding-right: 0;
  }
}

.alwaysRelative {
  position: relative !important;
  padding: var(--space-5) 0 var(--space-2) !important;
}
