/* https://developer.apple.com/documentation/apple_pay_on_the_web/displaying_apple_pay_buttons_using_css */

.applePayButton {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: plain;
  -apple-pay-button-style: black;
  width: 100%;
  height: 55px;
  border-radius: 20px;
  box-sizing: border-box;
}
