.bottomDrawer {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (--breakpointLg) {
  .contentCrateDrawer {
    left: var(--headerCrateProportion);
    width: var(--contentCrateProportion);
  }
}
