.defaultContainer {
  margin-left: var(--outerGutter);
  margin-right: var(--outerGutter);
}

@media (--breakpointMd) {
  .mediumContainer {
    margin-left: var(--logoOffsetMd);
    margin-right: var(--outerGutter);
  }
}

@media (--breakpointLg) {
  .largeContainer {
    margin-left: var(--logoOffsetLg);
    margin-right: var(--outerGutterLarge);
  }
}
