:root {
  --defaultHeartSize: 100px;
}

.heartsContainer {
  position: absolute;
  left: 50%;
  bottom: var(--defaultHeartSize);
  transform: translateX(calc(-0.5 * var(--defaultHeartSize)));
  pointer-events: none;
}

@for $i from 1 to 4 {
  .heart-$(i) {
    --rotate: calc(random(10, 30) * var(--rotateDirection) * 1deg);
    --scale: calc(random($(i) * 2, 12 + $(i) * 2) * 0.1);
    --heartYPositionEnd: calc((140 + $(i) * 10) * -1%);
    --heartXPositionEnd: calc(var(--rotateDirection) * $(i) * 10%);
    position: absolute;
    z-index: -1;
    opacity: 0;
    transform: scale(var(--scale)) rotate(var(--rotate));
  }

  .heart-active-$(i) {
    --delay: calc($(i) * 2 * 0.1s);
    animation: heartAnimation-$(i) var(--heartsAnimationDuration) var(--delay);
    animation-timing-function: ease-in-out;
  }

  @keyframes heartAnimation-$(i) {
    0% {
      z-index: 2;
      opacity: 1;
    }
    100% {
      transform: translateX(var(--heartXPositionEnd))
        translateY(var(--heartYPositionEnd)) scale(var(--scale))
        rotate(var(--rotate));
      opacity: 0;
    }
  }
}
