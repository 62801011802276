:root {
  --deliTicketEntryDuration: 200ms;
  --deliTicketExitDuration: 100ms;
  --deliTicketMaxWidth: 584px;
}

.deliTicket {
  left: 0;
  right: 0;
  top: calc(var(--globalNavHeightMobile) + var(--space-2));
  cursor: pointer;
  pointer-events: none;
}

@media (--breakpointMd) {
  .deliTicket {
    top: calc(var(--globalNavHeightTablet) + var(--space-2));
  }
}

@media (--breakpointLg) {
  .deliTicket {
    top: calc(var(--globalNavHeightDesktop) + var(--space-2));
  }
}

.content {
  border-radius: var(--space-1);
  box-shadow: 0 2px 7px 0 var(--colorBlack30);
  pointer-events: all;
  max-width: var(--deliTicketMaxWidth);
}

:global(.notification-enter).deliTicket {
  opacity: 0;
  transform: translateY(-40px);
}

:global(.notification-enter-active).deliTicket {
  transition: var(--deliTicketEntryDuration) ease-out;
  transition-property: opacity, transform;
  opacity: 1;
  transform: translateY(0px);
}

:global(.notification-exit).deliTicket {
  opacity: 1;
  transform: translateY(0px);
}

:global(.notification-exit-active).deliTicket {
  transition: var(--deliTicketExitDuration) ease-out;
  transition-property: opacity, transform;
  opacity: 0;
  transform: translateY(-40px);
}
