@keyframes fadeOut {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}

@keyframes fadeInAndSlideUp {
  0% {
    transform: translateY(33%);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.parent {
  height: 100%;
}

.child {
  position: relative;
}

.fadeOut {
  animation: fadeOut ease-out 1s;
}

.fadeInAndSlideUp {
  animation: fadeInAndSlideUp ease-out 1s;
}
