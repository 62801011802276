.overlayImageBlock {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}

.staticMap {
  border-radius: 5px;
  width: 100%;
  height: 120px;
}

.overlayPickupOption {
  border-radius: 8px;
  background: var(--colorNeutralCool);
}

.iconWrapper {
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 48px;
  height: 48px;
}
