@media (--breakpointCrateLayout) {
  .base {
    height: 100%;
  }
}

.siren {
  width: 40px;
  height: 40px;
}

@media (--breakpointMd) {
  .siren {
    width: 48px;
    height: 48px;
  }
}
