.sb-textArea {
  display: block;
  position: relative;
  padding-top: var(--fieldPadding);
  padding-bottom: var(--fieldPadding);
  padding-left: var(--space-3);
  padding-right: var(--space-3);
  /* Negative margin allows the animation ripple to go beyond the bounds of any parent, while keeping the border of the field lined up with the parent. */
  margin-left: calc(-1 * var(--space-3));
  margin-right: calc(-1 * var(--space-3));
  z-index: 0;
}

.sb-textAreaInput {
  border-radius: 8px;
  background-color: var(--colorWhite);
  z-index: 0;
  padding: 12px 16px 12px 16px;
  width: 100%;
  font-size: var(--floatLabelFontSizeMobile);
  resize: vertical;
  min-height: 48.5px;
}

.sb-textAreaInput:focus-visible {
  background-image: linear-gradient(
    var(--validFieldTintBackground),
    var(--validFieldTintBackground)
  );
}

.textArea__floatLabel {
  top: 30px;
}

.sb-textArea__childWrapper {
  position: relative;
}

@media (--breakpointMd) {
  .sb-textAreaInput {
    font-size: var(--floatLabelFontSizeDesktop);
  }
}
