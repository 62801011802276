.cardIcon {
  background-repeat: no-repeat;
  background-position: left 2rem;
  background-size: 100%;
  width: 40px;
}

.amex {
  composes: cardIcon;
  background-image: url('../../../../shared/app/components/img/amex.svg');
}

.defaultCC {
  composes: cardIcon;
  background-image: url('../../../../shared/app/components/img/credit-card.svg');
}

.discover {
  composes: cardIcon;
  background-image: url('../../../../shared/app/components/img/discover.svg');
}

.mastercard {
  composes: cardIcon;
  background-image: url('../../../../shared/app/components/img/mastercard.svg');
}

.visa {
  color: red;
  composes: cardIcon;
  background-image: url('../../../../shared/app/components/img/visa.svg');
}

.paypal {
  composes: cardIcon;
  background-image: url('../../../../shared/app/components/img/paypal.png');
}

.venmo {
  composes: cardIcon;
  background-image: url('../../../../shared/app/components/img/venmo.svg');
}
