.slider {
  transition: 100ms ease-out transform;
  transform: translateY(0);
}

.sliderActive {
  composes: slider;
  transition-duration: 200ms;
}

@media (--breakpointCrateLayout) {
  .mobileOnly {
    transform: none !important;
    transition: none;
  }
}
