@import './vars.css';

:root {
  --barShort: 3px;
  --barTall: 15px;
  --barRadius: 1.5px;
}

.maxTrackerWidth {
  max-width: var(--trackerMaxWidth);
}

.barBackground {
  background-color: var(--colorBlack20);
  height: var(--barShort);
  border-radius: var(--barRadius);
}

.progressBar {
  position: absolute;
  top: 0;
  left: 0;
  height: var(--barShort);
  border-radius: var(--barRadius);
}

/* Applied to GoalMarkers */
@keyframes shrinkAndFadeMarkersAnim {
  0% {
    opacity: 1;
    transform: scale(0.25);
  }
  50%,
  100% {
    opacity: 0;
    transform: scale(0.1);
  }
}
/* Applied to TrackerPointer */
@keyframes shrinkAndFadePointerAnim {
  0% {
    opacity: 1;
    transform: scale(1);
  }
  50%,
  100% {
    opacity: 0;
    transform: scale(0.25);
  }
}

@keyframes expandStarsBarAnim {
  0%,
  50% {
    opacity: 0;
  }
  51% {
    opacity: 1;
    height: var(--barShort);
  }
  100% {
    height: var(--barTall);
  }
}

.shrinkAndFadeMarkers {
  animation: shrinkAndFadeMarkersAnim 2s;
}

.shrinkAndFadePointer {
  animation: shrinkAndFadePointerAnim 2s;
}

/*
This class combination is necessary to make it so AnimateStarsBar 
doesn't have to re-render when the .active class is toggled 
otherwise the stars will change size and cause animation jank.
*/
.active .expandStarsBar {
  animation: expandStarsBarAnim 2s;
}
