.sbRadioToggle {
  border: 1px solid;
  line-height: 1.2;
  border-radius: 50px;
}

.sbRadioToggleSlider {
  padding: 7px 16px;
  border-radius: 50px;
}

.sbRadioToggleSlider:has(:checked) {
  background: var(--colorGreenAccent);
  color: var(--colorWhite);
}

.sbRadioToggleSlider:not(:has(:checked)):hover {
  background-color: hsl(var(--colorGreenAccentHsl) / 10%);
}

.sbRadioToggleSlider:has(:checked:focus-visible) {
  outline: 2px solid var(--colorWhite);
  outline-offset: -4px;
}

.radio {
  appearance: none;
}

.radio:focus-visible {
  /* suppress focus styling since we show it on the label */
  outline: none;
}
