.cardContainer {
  background: var(--colorHouseGreen);
  background-image: linear-gradient(
    to left bottom,
    #0c2720,
    #0c2720,
    transparent 60%
  );
}

@media (--breakpointLg) {
  .cardContainer {
    max-width: 500px;
  }
}
