.container {
  border-radius: 4px;
}

.starCount {
  padding-right: 2px;
}

.firstColumn {
  min-width: var(--space-9);
}
