@import './vars.css';

:root {
  --sprite-color: #fae26a;
  --buffer: -15px;
  --maxWidth: calc(var(--trackerMaxWidth) + var(--buffer) * -2);
}

.container {
  margin: auto;
  height: 15px;
  top: 0;
  bottom: 0;
  border-radius: 7px;
  /* fix stars visible outside rounded corners due to safari bug */
  /* see https://gist.github.com/adamcbrewer/5859738 */
  z-index: 1;
}

@keyframes move-bubble {
  0% {
    transform: translate3d(var(--buffer), -50%, 0);
  }
  100% {
    transform: translate3d(var(--maxWidth), -50%, 0);
  }
}

.bubble {
  background-color: var(--sprite-color);
  left: var(--buffer);
  border-radius: 50%;
}

/* Note: When using random(), which is a PostCSS feature, prettier will add a space between  
 * the unit (if used) and the value, for example "random(1,5)px" becomes "random(1,5) px"
 * which is incorrect (prettier is not aware of random() as a language feature). 
 * To work around this, we use calc(), ie. "calc(random(1,5) * 1px)". 
 * Calc allows units in the expression as needed.
 */

/* A unique size, top, opacity and animation for each bubble */
@for $i from 0 to 19 {
  .bubble$(i) {
    --size: calc(random(1, 5) * 1px);
    height: var(--size);
    width: var(--size);
    top: calc(random(0, 100, {round: true}) * 1%);
    opacity: random(0, 1);
    animation: move-bubble calc(random(10, 20) * 1s) calc(random(0, 12) * 1s)
      linear infinite;
  }
}

.star {
  color: var(--sprite-color);
  left: var(--buffer);
}

@for $i from 0 to 19 {
  /* A unique animation/transforms for each star */
  @keyframes move-star-$(i) {
    0% {
      transform: translate3d(var(--buffer), -50%, 0) rotate(0deg);
    }
    100% {
      transform: translate3d(var(--maxWidth), -50%, 0)
        rotate(calc(random(-3600, 3600) * 1deg));
    }
  }

  /* A unique top, opacity and animation for each star */
  .star$(i) {
    top: calc(random(0, 100, {round: true}) * 1%);
    opacity: random(0.5, 1);
    animation: move-star-$(i) calc(random(10, 20) * 1s) calc(random(0, 12) * 1s)
      linear infinite;
  }
}
