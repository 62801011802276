.svcFlex {
  width: 40px;
}

.svcImage {
  position: absolute !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

.fallbackImage {
  height: auto !important;
}
